.bg-sec-1{
    background-color: #220B21;
    height: 100vh;
}

.card{
    border-radius: 10px;
    height: 500px;
    /* offset-x | offset-y | blur-radius | color */
}
.login-afrowatch-logo{
    width: 20%;
}

@media(max-width:700px){
    .login-afrowatch-logo{
        width: 20%;
    }
}

.custom-form-border{
    border: 1px solid #220B21!important;
    color: #220B21!important;
    border-radius: 4px;
}