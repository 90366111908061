.chart-skeleton{
    border: 1px solid black;
    margin-left: 30px;
    /* padding-right: 23px; */
    width: fit-content;
}

.chart-skeleton-pie{
    border: 1px solid black;
    margin-left: 30px;
    padding-right: 23px;
    width: fit-content;
    padding-bottom: 25px;
    padding-top: 25px;
}

.MuiChartsAxis-label{
    font-weight: 800!important;
}

@media (min-width: 900px) {
    /* .css-5vlm5e-MuiGrid-root>.MuiGrid-item {
        padding-left: 150px!important;
    } */
}