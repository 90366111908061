.navbar-nav .nav-link.active{
    background: #3B193A;
    color: #FFFFFF;
}

.navbar-nav .nav-link {
    background-color: transparent;
    color: white;
    border-radius: 5px;
}

.navbar-nav .nav-link:hover {
    background-color: #5b375a;
    color: white;
    border-radius: 5px;
    transition: 400ms ease-in-out;
}

.navbar-toggler:focus{
    box-shadow: none!important;
}

#independent-link{
    color: #12c38d;
    border: #12c38d 1px solid;
}