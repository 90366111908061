.nav-tabs .nav-link{
    color: #B4B4B4;
    border-bottom: 3px solid  #B4B4B4;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #1f0f25;
    margin-right: 30px;
    margin-left: 30px;
    width: 120px;
}

.nav-tabs .nav-link.active {
    color: #5b375a;
    background-color: transparent;
    border-color: #5b375a;
    /* border-top: none;
    border-left: none;
    border-right: none; */
    border-bottom: 3px solid #5b375a;
}

.nav-tabs .nav-link:hover {
    /* border-top: none;
    border-left: none;
    border-right: none; */
    border-bottom: 3px solid #5f5064;
}

.nav-tabs{
    border: none;
}

.card{
    height: 550px;
}