.nav-drawer a{
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
}

.dashboard-icon{
    width: 35px;
}

/* Drawer */
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
    background: #2D132C!important;
    color: white!important;
}

/* App Bar with Drawer Closed*/
.css-19z1ozs-MuiPaper-root-MuiAppBar-root{
    background: transparent!important;
    box-shadow: none!important;
}

/* App Bar with Drawer Opened*/
.css-1s30sax-MuiPaper-root-MuiAppBar-root {
    background: transparent!important;
    box-shadow: none!important;
}

/* Icon padding List Item in Drawer */
.css-1p823my-MuiListItem-root{
    /* padding-left: 2px!important; */
}

@media screen and (min-width:600px) {
    .nav-drawer{
        background: #220B21;
        box-shadow: 0px 4px 9px 5px rgba(0, 0, 0, 0.09);
        position: absolute;
        left: 0px;
        width: 15%;
        text-align: left;
        height: 200vh;
    }

    .nav-afrowatch-logo{
       width: 100px;
       margin-left: 0px;
    }

    .nav-closed-afrowatch-logo{
        width: 50px;
        margin-left: 0px;
    }
}

@media(max-width:700px){
    .nav-drawer{
        background: #220B21;
        box-shadow: 0px 4px 9px 5px rgba(0, 0, 0, 0.09);
        position: absolute;
        left: 0px;
        width: 15%;
        text-align: left;
        height: 120vh;
    }

    .nav-afrowatch-logo{
       width: 50%;
       margin-left: 10px;
    }

}

.activeTab{
    background: rgba(255, 255, 255, 0.19);
}

.inactiveTab{
    background: #220B21;
}

.afrowatch-color{
    background: #220B21;
    color: white;
}

.nav-link.disabled {
    color: rgb(133, 133, 133)!important;
}