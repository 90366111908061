.movie-card{
    height: 280px!important;
    width: 250px;
    font-size: 14px;
    background-color: #331832;
    color: white;
    border: 2px solid  #3b193a;
}

.movie-card-admin{
    height: 280px!important;
    /* width: 250px; */
    font-size: 14px;
    margin: 8px;
}

.content-season-card{
    height: 100px!important;
    width: 250px;
    font-size: 14px;
    background-color: #331832;
    color: white;
    border: 2px solid  #3b193a;
}


@media (min-width: 900px){
    .movie-row-one{
        position: absolute;
        left: 290px;
        top: 125px;
    }

    .movie-card-container{
        margin-top: 20px;
    }
}

.text-left{
    text-align: left;
}


@media (min-width: 1200px){
    
}