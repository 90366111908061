.file_label:after{
    content: ' or Drag and drop files';
}

.file-size-text{
    font-weight: 500;
    font-size: 14px;
}

.text-content-width{
    width: 100%;
}

.actor-button{
    background-color: #C5C5C5;
    border-radius: 50px;
    width: 60px;
    padding: 2px 2px 2px 5px;
    text-align: center;
    font-size: 12px;
}

.actor-button:hover{
    filter: blur(3);
}

.delete-btn{
    /* background-color: #C5C5C5!important; */
    font-size: 10px!important;
    height: 35px!important;
}

.submit-show{
    display: block;
} 

.submit-hide{
    display: none;
}

.bg-Dashboard{
    /* background: #1F0F25; */
}