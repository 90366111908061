/* .css-kxqvk8-MuiGrid-root {
    margin-left: -432px!important;
} */

.css-19kzrtu{
    padding-left: 0px!important;
}

@media (min-width: 600px) {
    .css-1xqlx8c-MuiContainer-root {
        padding-left: 0px!important;
        padding-right: 24px;
    }
}