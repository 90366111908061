.search{
    background: url('https://res.cloudinary.com/www-softroniiks-com/image/upload/v1619866913/AfroWatch/Vector_jxclsd.svg');
    background-repeat: no-repeat;
    background-position: 10px 14px;
    border-radius: 30px;
    width: 450px;
    height: 50px;
    outline: none;
    color: #2D132C;
    padding-left: 40px;
}

.notification-btn{
    background: none;
}

.search{
    background-color: white;
}

.bg-dark{
    background-color: #2D132C!important;
    width: 100vw;
    position: absolute;
    left: 0px;
    top:0px;
}
